<template>
  <div class="add-frends">
    <div class="head">
      <img src="../../assets/img/commoditySpike/back.png" alt="" @click="$router.back()" />
      <div class="center">
        <img src="../../assets/img/takeOutFood/Search.png" alt="" />
       <input type="text" placeholder="请输入对方邀请码添加好友" v-model="value"  v-focus  ref="search" />
      </div>
    </div>
    <div class="main">
    <div class="search" v-show="value!==''">
        <img src="../../assets/img/lookfor.png" alt="">
        <div class="search-content">搜索：<span>{{value}}</span></div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            value:''
        }
        
    },
    directives: {
  focus: {
    // 指令的定义
    inserted: function (el) {
      el.focus()
    }
  }
},
  methods: {
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
  },
  mounted(){
      this.setRem()
      window.addEventListener('orientationchange',this.setRem)
      window.addEventListener('resize',this.setRem)
      this.$nextTick(()=>{
        //   this.$refs.search.focus()
      })
  },
  // 销毁之前
   beforeDestroy(){
     document.documentElement.style.fontSize =  "16px";
  },
  
  }

</script>

<style lang="less" scoped>
.add-frends {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  font-family: PingFangSC-Semibold, PingFang SC;
  .head {
    display: flex;
    align-items: center;
    padding:.266667rem;
    img {
      width: 0.666667rem;
      height: 0.64rem;
    }
    .center {
        height: .64rem;
        margin-left: .586667rem;
        color: #999;
        font-size: 0.373333rem;
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin-right: .266667rem;
      }
      input{
          flex: 1;
          height: 100%;
      }
    }
  }
  .main{
      flex: 1;
      .search{
          display: flex;
          margin-left:.4rem ;
          align-items: center;
          height: 2.026667rem;
          font-size: .453333rem;
          border-bottom: .013333rem solid #E4E4E4;
          img{
              width: 1.2rem;
              height: 1.2rem;
          }
          .search-content{
              margin-left: .266667rem;
              font-weight: 600;
              span{
                  color: #FF5200;
              }
          }
      }
  }
}
</style>